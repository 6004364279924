import cookies from 'js-cookie'
import 'intersection-observer'
import md5 from 'md5'

import {
  PAGE_TYPE_BRAND_PAGE,
  PAGE_TYPE_CART,
  PAGE_TYPE_CART_INTERMEDIATE,
  PAGE_TYPE_CATEGORY,
  PAGE_TYPE_PRODUCT,
} from 'shared/consts'
import {
  AD_SERVER_EXPERIMENTS_PREFIX,
  AD_SERVER_METADATA_DATA_ATTR,
  AD_SERVER_USER_ID_COOKIE,
} from 'shared/consts/adServer'
import { KEVEL_AD_SERVER_VARIANT } from 'shared/experiments/consts'
import logger from 'shared/services/logger'
import store, { subscribe } from 'shared/store'
import { actionNames } from 'shared/store/ducks/userSession'
import { getSetAdUserIdentity } from 'shared/utils/adServerUtils'
import {
  getProductIdsFromCart,
  getUpidsFromCart,
  hasCartRxProducts,
} from 'shared/utils/cart'
import { isCookieConsent } from 'shared/utils/cookieConsent'
import { filterValues, isEmpty, pick } from 'shared/utils/objectUtils'
import { createTenantPath } from 'shared/utils/routing'
import {
  type AdServerContext,
  type AdServerMetadataContent,
  AdServerSlotStatus,
  type GtmEvent,
  type GtmEventCallback,
  GtmEventType,
  type Placement,
  type TargetingContextProps,
} from 'types/adServerTypes'
import type { CartControllerResponseV2 } from 'types/api/cart'
import { CLIENTSIDE_HOOK_DATA_ATTR } from 'views/assets/scripts/consts'
import Storage from 'views/assets/scripts/services/storage/storage'
import {
  AD_BANNER_AUTO_REFRESH_DATA_ATTR,
  AD_BANNER_CLIENTSIDE_HOOK,
  AD_BANNER_CLOSE_CLIENTSIDE_HOOK,
  AD_BANNER_CONTAINER_DATA_ATTR,
  AD_BANNER_CONTENT_DATA_ATTR,
  AD_BANNER_EXCLUDE_PAGE_SPECIFIC_CONTEXT_ATTR,
  AD_BANNER_FLIGHT_ID_DATA_ATTR,
  AD_BANNER_HEIGHT_DATA_ATTR,
  AD_BANNER_LEGAL_BUTTON_CLIENTSIDE_HOOK,
  AD_BANNER_LEGAL_BUTTON_INNER_TEXT_CLASS,
  AD_BANNER_LEGAL_BUTTON_LABEL_CLASS,
  AD_BANNER_LEGAL_BUTTON_TEXT_CLASS,
  AD_BANNER_STATUS_DATA_ATTR,
  AD_BANNER_STORAGE_KEY,
  AD_BANNER_TAGS_DATA_ATTR,
  AD_BANNER_TOP_BANNER_DISPLAY_DATA_ATTR,
  AD_BANNER_WIDTH_DATA_ATTR,
} from 'views/consts/adBanner'

type User = {
  customerNumber: string
}

interface UserSession {
  isLoggedIn: boolean
  user: User
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any

const storage = new Storage('localStorage')
const adBannerStorageValue: string | undefined = storage.get(
  AD_BANNER_STORAGE_KEY
)

const filterAdServerObject = (obj: AdServerContext): AdServerContext => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => {
      // ignore boolean type
      if (typeof value === 'boolean') {
        return true
      } else if (Array.isArray(value)) {
        return value.length && value.every(Boolean)
      }

      return Boolean(value?.trim())
    })
  ) as AdServerContext
}

export const getTargetingContext = (
  props: TargetingContextProps
): AdServerContext => {
  const {
    publicRuntimeConfig: { pageProperties, publicConfig },
    cart,
    adServerSideContext = {},
  } = props

  const {
    product,
    pageType,
    cmsContentCode,
    cmsContentName,
    expa = 'direct',
    coreShopEnvironment,
    resultsState,
    experiments = [],
    upNavTree = [],
  } = pageProperties

  const { tenant, language } = publicConfig
  const tenantWithEnv = createTenantPath(tenant, coreShopEnvironment)
  const adExperiments = experiments
    .filter(
      ({ isEnabled, name }) =>
        isEnabled && name.startsWith(AD_SERVER_EXPERIMENTS_PREFIX)
    )
    .map(({ name, variant }) => {
      return `${name.slice(AD_SERVER_EXPERIMENTS_PREFIX.length)}|${variant}`
    })

  const commonProps: AdServerContext = {
    ...adServerSideContext,
    expa,
    tenant: tenantWithEnv,
    pageType,
    language,
    experiments: adExperiments as string[],
  }

  const cartData: CartControllerResponseV2 | undefined = cart?.data

  if (
    product &&
    (pageType === PAGE_TYPE_PRODUCT || pageType === PAGE_TYPE_CART_INTERMEDIATE)
  ) {
    const {
      brandName,
      category,
      manufacturerCode,
      secondLevelCategories,
      id,
      upid,
      isRx,
    } = product

    const categories = category
      ? [category, ...secondLevelCategories]
      : secondLevelCategories

    return filterAdServerObject({
      ...commonProps,
      brandName,
      manufacturerCode,
      secondLevelCategories: categories,
      pzn: id,
      upid,
      rx: isRx,
    })
  } else if (pageType === PAGE_TYPE_CATEGORY) {
    let secondLevelCategories: string[] = []

    // L3 category page
    if (resultsState) {
      secondLevelCategories = [...upNavTree.map(({ code }) => code).slice(-2)]
    } else if (cmsContentCode) {
      secondLevelCategories = [cmsContentCode]
    }

    return {
      categoryId: cmsContentCode,
      secondLevelCategories: secondLevelCategories.length
        ? secondLevelCategories
        : undefined,
      ...commonProps,
    }
  } else if (pageType === PAGE_TYPE_BRAND_PAGE) {
    return filterAdServerObject({
      brandName: cmsContentName,
      ...commonProps,
    })
  } else if (pageType === PAGE_TYPE_CART && cartData) {
    return filterAdServerObject({
      pzn: getProductIdsFromCart(cartData),
      upid: getUpidsFromCart(cartData),
      rx: hasCartRxProducts(cartData),
      ...commonProps,
    })
  } else {
    return filterAdServerObject({
      ...commonProps,
    })
  }
}

export const loadAdServerDeps = () => {
  const {
    publicConfig: {
      adServer: { widgetUrl },
    },
  } = store.getPublicRuntimeConfig()
  const mainScriptTag = document.createElement('script')
  mainScriptTag.setAttribute('defer', '')
  mainScriptTag.src = widgetUrl
  mainScriptTag.onerror = err => {
    logger.error(`Failed to load ad-server client script: ${err}`)
  }
  document.head.appendChild(mainScriptTag)
}

export function syncUser(userSession: UserSession) {
  const { isLoggedIn, user: { customerNumber = '' } = {} } = userSession
  if (isLoggedIn && customerNumber) {
    // TODO We only want MD5 of the customer number, ideally we'd get it already hashed from API
    const crossEngageUserId = md5(customerNumber)
    cookies.set(AD_SERVER_USER_ID_COOKIE, crossEngageUserId)

    if (cookies.get(AD_SERVER_USER_ID_COOKIE)) {
      window._slots.push([
        'syncUser',
        {
          userId: cookies.get(AD_SERVER_USER_ID_COOKIE),
        },
      ])
    }
  }
}

export function syncInterest() {
  const { secondLevelCategories } = window._targeting?.context || {}
  if (secondLevelCategories) {
    window._slots.push([
      'syncInterest',
      {
        visitedCategories: secondLevelCategories,
      },
    ])
  }
}

const concatIds = (metadataContent: AdServerMetadataContent) => {
  const { campaignId, creativeId, flightId } = metadataContent

  return `${campaignId}:${flightId}:${creativeId}`
}

export const pushGtmEvent = (
  eventType: GtmEventType,
  metadataContent: AdServerMetadataContent,
  eventCallback?: GtmEventCallback
): void => {
  const ids: string = concatIds(metadataContent)
  const event: GtmEvent = {
    event: eventType,
    [eventType]: ids,
  }

  if (eventCallback) {
    event.eventCallback = eventCallback
  }

  /**
   * On SERP, we need to push the setupPreloadedSlots two times (before and after the hydration) for the same ads
   * If we push it only once, for example after the hydration, some events might be missed (e.g. view event due to the scrolling of the page)
   * That sometimes leads to the same event being pushed twice to the dataLayer
   * To avoid that, we need to check if the event already exists in the dataLayer
   */
  const isGTMEventExist = window.dataLayer.some(
    (item: GtmEvent) => item?.[eventType] === ids
  )

  if (!isGTMEventExist) {
    window.dataLayer.push(event)
  }
}

export const loadBanners = ({
  context,
  slots,
}: {
  context?: AdServerContext
  slots: NodeListOf<HTMLElement> | Array<HTMLElement>
}): void => {
  window._slots = window._slots || []
  window._slots.push(['load', { slots, context }])
}

const pushInitCommand = () => {
  const {
    pageProperties: { coreShopEnvironment },
    publicConfig: { tenant, language },
  } = store.getPublicRuntimeConfig()

  const tenantPath = createTenantPath(tenant, coreShopEnvironment)
  const identity = filterValues(Boolean, getSetAdUserIdentity(cookies))

  window._slots = window._slots || []
  window._slots.push([
    'init',
    {
      identity,
      language,
      tenant: tenantPath,
      cookieConsent: isCookieConsent(cookies),
      getKeywords: () => window._targeting?.keywords || [],
      getContext: (slots: Array<HTMLElement> | NodeListOf<HTMLElement>) => {
        const context = window._targeting?.context || {}
        const slotsWithExcludeAttribute = [...slots].filter(
          (slot: HTMLElement) => {
            return (
              slot.getAttribute(
                AD_BANNER_EXCLUDE_PAGE_SPECIFIC_CONTEXT_ATTR
              ) === 'true'
            )
          }
        )

        // ignore the page specific context
        // A ticket is created to improve the handling of the targeting context
        // https://jira.shop-apotheke.com/browse/RMADT-923
        if (slotsWithExcludeAttribute.length === slots.length) {
          return pick(context, [
            'expa',
            'tenant',
            'partnerCode',
            'adWord',
            'pageType',
            'language',
            'experiments',
          ])
        }

        if (slotsWithExcludeAttribute.length > 0) {
          logger.error(
            `AdServer load: all slots need to have the same value of ${AD_BANNER_EXCLUDE_PAGE_SPECIFIC_CONTEXT_ATTR} attribute. Split your load in two`
          )
        }

        return context
      },
      getSlotTags: (slot: HTMLElement) =>
        slot.getAttribute(AD_BANNER_TAGS_DATA_ATTR)?.split(','),
      getSlotSize: (slot: HTMLElement) => {
        const widthString = slot.getAttribute(AD_BANNER_WIDTH_DATA_ATTR)
        const heightString = slot.getAttribute(AD_BANNER_HEIGHT_DATA_ATTR)
        if (widthString && heightString) {
          const width = parseInt(widthString, 10)
          const height = parseInt(heightString, 10)
          if (!Number.isNaN(width) && !Number.isNaN(height)) {
            return { width, height }
          }
        }
      },
      getSlotAutoRefreshConfig: (slot: HTMLElement) => {
        const autoRefreshConfig = slot.getAttribute(
          AD_BANNER_AUTO_REFRESH_DATA_ATTR
        )

        if (autoRefreshConfig) {
          try {
            const { maxCount, intervalMs } = JSON.parse(autoRefreshConfig)

            if (maxCount && intervalMs) {
              return { maxCount, intervalMs }
            } else {
              logger.error('Incomplete auto refresh config', {
                autoRefreshConfig,
              })
            }
          } catch (error) {
            logger.error('Failed to parse auto refresh config', {
              error,
              autoRefreshConfig,
            })
          }
        }

        return null
      },
      onStatusChange: (slot: HTMLElement, status: AdServerSlotStatus) => {
        slot.setAttribute(AD_BANNER_STATUS_DATA_ATTR, status)

        if (status === AdServerSlotStatus.READY) {
          slot
            .querySelector(`[${AD_BANNER_CONTAINER_DATA_ATTR}]`)
            ?.firstElementChild?.setAttribute(AD_BANNER_CONTENT_DATA_ATTR, '')
        }
      },
      mapPlacement: (
        slot: HTMLElement,
        placement: Placement | null
      ): Placement | null => {
        if (!placement) {
          return null
        }

        const flightIds: string[] | undefined = adBannerStorageValue?.split(',')

        const { flightId } = placement

        if (flightIds?.includes(flightId.toString())) {
          return null
        }

        return placement
      },
      onPlaced: (
        slot: HTMLElement,
        metadataContent: AdServerMetadataContent
      ) => {
        const { flightId, customData } = metadataContent

        slot.setAttribute(AD_BANNER_FLIGHT_ID_DATA_ATTR, `${flightId}`)

        if (customData?.topBannerDisplay) {
          slot.setAttribute(
            AD_BANNER_TOP_BANNER_DISPLAY_DATA_ATTR,
            customData?.topBannerDisplay
          )

          document.documentElement?.setAttribute(
            AD_BANNER_TOP_BANNER_DISPLAY_DATA_ATTR,
            customData?.topBannerDisplay
          )
        }

        if (customData?.closeButton) {
          slot
            ?.querySelector(
              `[${CLIENTSIDE_HOOK_DATA_ATTR}="${AD_BANNER_CLOSE_CLIENTSIDE_HOOK}"]`
            )
            ?.removeAttribute('hidden')
        }

        if (customData?.legalButton?.label && customData?.legalButton?.text) {
          const legalButtonElement = slot?.querySelector(
            `[${CLIENTSIDE_HOOK_DATA_ATTR}="${AD_BANNER_LEGAL_BUTTON_CLIENTSIDE_HOOK}"]`
          ) as HTMLButtonElement | null

          if (legalButtonElement) {
            const legalButtonLabel = document.createElement('span')
            legalButtonLabel.classList.add(AD_BANNER_LEGAL_BUTTON_LABEL_CLASS)
            legalButtonLabel.textContent = customData.legalButton.label

            const legalTextSpan = document.createElement('span')
            legalTextSpan.classList.add(AD_BANNER_LEGAL_BUTTON_TEXT_CLASS)
            const legalTextInnerSpan = document.createElement('span')
            legalTextInnerSpan.classList.add(
              AD_BANNER_LEGAL_BUTTON_INNER_TEXT_CLASS
            )
            legalTextInnerSpan.textContent = customData.legalButton.text
            legalTextSpan.appendChild(legalTextInnerSpan)

            legalButtonElement.appendChild(legalButtonLabel)
            legalButtonElement.appendChild(legalTextSpan)
            legalButtonElement.removeAttribute('hidden')

            legalButtonElement.addEventListener('click', () => {
              if (legalButtonElement.classList.contains('active')) {
                legalButtonElement.classList.remove('active')
              } else {
                legalButtonElement.classList.add('active')
              }
            })
          }
        }
      },
      onView: (metadataContent: AdServerMetadataContent) => {
        if (!isEmpty(metadataContent)) {
          pushGtmEvent(GtmEventType.viewEvent, metadataContent)
        }
      },
      onImpression: (metadataContent: AdServerMetadataContent) => {
        if (!isEmpty(metadataContent)) {
          pushGtmEvent(GtmEventType.impressionEvent, metadataContent)
        }
      },
      // if GTM `eventCallback` fails to fire the gtm event
      // It leads that the click url might not work
      // So as a fallback, there is a setTimeout to trigger click event
      onClick: (metadataContent: AdServerMetadataContent) => {
        return new Promise(resolve => {
          if (!isEmpty(metadataContent)) {
            pushGtmEvent(GtmEventType.clickEvent, metadataContent, resolve)
          }
        })
      },

      handleError(error: Error) {
        logger.error(error)
      },
      adEngine: KEVEL_AD_SERVER_VARIANT,
    },
  ])
}

const loadAutoLoadSlots = () => {
  // This one gathers all slots that will be rendered automatically when
  // initialising adServer. In order to make sure that your ad is placed,
  // pls include data-auto-load attr into your placeholder/slot.
  const slots: NodeListOf<HTMLElement> = document.querySelectorAll(
    `[data-clientside-hook="${AD_BANNER_CLIENTSIDE_HOOK}"][data-auto-load]`
  )

  loadBanners({ slots })
}

export const pushSetupPreloadedSlotsCommand = (container?: HTMLElement) => {
  const root = container ? container : document
  const slots = [...root.querySelectorAll(`[${AD_SERVER_METADATA_DATA_ATTR}]`)]

  if (slots?.length) {
    const slotMetadataContentPair = slots.map(slot => {
      const metadataContent: AdServerMetadataContent = JSON.parse(
        slot.getAttribute(AD_SERVER_METADATA_DATA_ATTR) || '{}'
      )

      return {
        slot,
        metadataContent,
      }
    })

    window._slots?.push(['setupPreloadedSlots', slotMetadataContentPair])
  }
}

export const attachCloseAdBannerEvents = () => {
  const closeBannerButtons = [
    ...document.querySelectorAll(
      `[${CLIENTSIDE_HOOK_DATA_ATTR}="${AD_BANNER_CLOSE_CLIENTSIDE_HOOK}"]`
    ),
  ]

  if (closeBannerButtons.length) {
    closeBannerButtons.forEach(closeBannerButton => {
      closeBannerButton.addEventListener('click', () => {
        const adBanner = closeBannerButton?.parentElement
        const flightId = adBanner?.getAttribute(AD_BANNER_FLIGHT_ID_DATA_ATTR)

        if (adBanner && flightId) {
          const topBannerDisplay = adBanner.getAttribute(
            AD_BANNER_TOP_BANNER_DISPLAY_DATA_ATTR
          )

          if (topBannerDisplay) {
            document.documentElement?.removeAttribute(
              AD_BANNER_TOP_BANNER_DISPLAY_DATA_ATTR
            )
          }

          adBanner.remove()

          const adBannerStorageNewValue = adBannerStorageValue
            ? `${adBannerStorageValue},${flightId}`
            : flightId

          storage.set(AD_BANNER_STORAGE_KEY, adBannerStorageNewValue)
        }
      })
    })
  }
}

export const initAdServer = () => {
  loadAdServerDeps()
  pushInitCommand()
  loadAutoLoadSlots()
  syncInterest()
  pushSetupPreloadedSlotsCommand()

  // @ts-ignore -- .once is not properly typed by us
  subscribe.after.once(
    actionNames.setUserSession,
    ({ userSession }: { userSession: UserSession }) => {
      syncUser(userSession)
    }
  )
}
